/*----------------------------------
    #Mountly Quiz - Steps
----------------------------------*/
.bg-progress-bar {
    background: {
        image: url("/images/new_design/bg-progress-bar.png");
        position: center bottom;
        repeat: no-repeat;
    }
}

.container.container-progress-bar {
    margin-bottom: 3rem;
}

.progress-1 {
    background: {
        image: url("/images/new_design/bg-progress-bar.png");
        position: left center;
        repeat: no-repeat;
    }
    height: 4rem;
    margin: 0 auto;
    max-width: 120rem;
    position: relative;
    width: 100%;
}

.progress-2 {
    background: {
        image: url("/images/new_design/bg-progress-bar-gray.png");
        position: left center;
        repeat: no-repeat;
    }
    height: 4rem;
    left: 0;
    position: absolute;
    text-align: right;
    top: 0;
}

.progress-3 {
    background: {
        image: url("/images/new_design/bg-progress-bar-green.png");
        position: left center;
        repeat: no-repeat;
    }
    height: 4rem;
    left: 0;
    position: absolute;
    top: 0;
    right: 3.8rem;
}

.progress-number {
    color: $green;
    font: {
        family: $caveat;
        size: 2.3rem;
    }
    line-height: 4rem;
    letter-spacing: -0.2rem;
    padding-right: 0.2rem;
    text-align: right;
}

.quiz-steps-container {
    margin: 3rem 0;

    .container {
        max-width: 1200px;
    }
}

.quiz-steps {
    width: 100%;
    @extend %display-columns;
}

.quiz-step {
    color: $orange;
    margin: 1rem 0;

    &--past {
        color: $grey-tint-4;
    }

    &__title {
        font-family: $caveat;
        font-size: 2.1rem;
        letter-spacing: -1px;
    }

    &__info {
        color: $grey-tint-4;
        font-size: 1.3rem;
        line-height: 1.7;
    }
}

.quiz-container {
    margin: 0 2rem;
}

/*----------------------------------
    Tabel - Questions
----------------------------------*/
.Table {
    clear: both;
    text-align: left;
    width: 100%;

    .question-head {
        display: none;
    }

    .question-row {
        margin-bottom: 1rem;
    }

    .question-head-col,
    .question-head-label {
        font-size: 1.3rem;
        line-height: 2.3rem;
        opacity: 0.6;
        text-transform: capitalize;
    }

    &.Horizontal {
        .question-head-col,
        .question-head-label {
            opacity: 1;
        }
    }
}


/*----------------------------------
    #Admin Ivox
----------------------------------*/
.user-links {
    background-color: $grey-tint-2;
    display: block;
    float: right;
    font-size: 1.3rem;
    margin-top: -1.5rem;
    padding: 0.5rem;
    text-align: right;

    a {
        font-size: 1.3rem;

        &.link-edit {
            color: $green;
        }

        &.link-view {
            color: $dark-blue;
        }

        &.link-options {
            color: $dark-blue;
        }
    }
}