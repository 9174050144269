/*----------------------------------
    #FONTS
----------------------------------*/
/*----------------------------------
    #Caveat Brush
----------------------------------*/
@font-face {
  font-family: 'Caveat Brush';
  src: url('/public/fonts/caveat-brush/CaveatBrush-Regular.woff') format('woff'),
       url('/public/fonts/caveat-brush/CaveatBrush-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*----------------------------------
    #Muli Black
----------------------------------*/
@font-face {
    font-family:'Muli Black';
    src: url('/public/fonts/muli/Muli Black.eot');
    src: url('/public/fonts/muli/Muli Black.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/muli/Muli Black.woff') format('woff'),
         url('/public/fonts/muli/Muli Black.svg#Muli Black') format('svg');
    font-weight: 900;
    unicode-range: U+0020-2044;
}

/*----------------------------------
    #Muli
----------------------------------*/
@font-face {
    font-family: 'Muli';
    src: url('/public/fonts/muli/muli-bold-webfont.woff2') format('woff2'),
         url('/public/fonts/muli/muli-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Muli';
    src: url('/public/fonts/muli/muli-extrabold-webfont.woff2') format('woff2'),
         url('/public/fonts/muli/muli-extrabold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family:'Muli';
    src: url('/public/fonts/muli/Muli Regular.eot');
    src: url('/public/fonts/muli/Muli Regular.eot?#iefix') format('embedded-opentype'),
         url('/public/fonts/muli/Muli Regular.woff2') format('woff2'),
         url('/public/fonts/muli/Muli Regular.woff') format('woff'),
         url('/public/fonts/muli/Muli Regular.svg#Muli Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family: 'Muli';
    src: url('/public/fonts/muli/muli-regular-webfont.woff2') format('woff2'),
         url('/public/fonts/muli/muli-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Muli';
    src: url('/public/fonts/muli/muli-light-webfont.woff2') format('woff2'),
         url('/public/fonts/muli/muli-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Muli';
    src: url('/public/fonts/muli/muli-extralight-webfont.woff2') format('woff2'),
         url('/public/fonts/muli/muli-extralight-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}


$muli: 'Muli', sans-serif;
$muliBlack: 'Muli Black', sans-serif;

$caveat: 'Caveat Brush', cursive;

$font-application: $muli;
$font-application-black: $muliBlack;
