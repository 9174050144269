/*----------------------------------
    #SCROLLBAR
----------------------------------*/

.scroll-wrapper {
    overflow: hidden !important;
    margin-bottom: 1rem;
    padding: 0 !important;
    position: relative;

    > .scroll-content {
        border: none !important;
        box-sizing: content-box !important;
        height: auto;
        left: 0;
        margin: 0;
        max-height: none;
        max-width: none !important;
        overflow: scroll !important;
        padding: 0;
        position: relative !important;
        top: 0;
        width: auto !important;
    }

    > .scroll-content::-webkit-scrollbar {
        height: 0;
        width: 0;
    }
}

.scroll-element {
    display: none;
}

.scroll-element,
.scroll-element div {
    box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}

.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;

    > .scroll-content {
        overflow: hidden !important;

        > textarea {
            border: none !important;
            box-sizing: border-box;
            height: 100% !important;
            margin: 0;
            max-height: none !important;
            max-width: none !important;
            overflow: scroll !important;
            outline: none;
            padding: 2px;
            position: relative !important;
            top: 0;
            width: 100% !important;
        }

        > textarea::-webkit-scrollbar {
            height: 0;
            width: 0;
        }
    }
}

/*************** SIMPLE INNER SCROLLBAR ***************/

.scrollbar-inner {
    > .scroll-element,
    > .scroll-element div {
        border: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 10;
    }

    > .scroll-element {
        div {
            display: block;
            height: 100%;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.scroll-x {
            bottom: 2px;
            height: 8px;
            left: 0;
            width: 100%;
        }

        &.scroll-y {
            height: 100%;
            right: 2px;
            top: 0;
            width: 8px;
        }

        .scroll-element_outer {
            overflow: hidden;
        }

        .scroll-element_outer,
        .scroll-element_track,
        .scroll-bar {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }

        .scroll-element_track,
        .scroll-bar {
            -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
            filter: alpha(opacity=40);
            opacity: 0.5;
        }

        .scroll-element_track {
            background-color: $grey-tint-3;
        }

        .scroll-bar {
            background-color: $grey-tint-4;
        }

        &:hover {
            .scroll-bar {
                background-color: $dark-blue;
            }
        }

        &.scroll-draggable {
            .scroll-bar {
                background-color: $dark-blue;
            }
        }
    }
}

/* update scrollbar offset if both scrolls are visible */

.scrollbar-inner {
    > .scroll-element {
        &.scroll-x {
            &.scroll-scrolly_visible {
                .scroll-element_track {
                    left: -12px;
                }

                .scroll-element_size {
                    left: -12px;
                }
            }
        }

        &.scroll-y {
            &.scroll-scrollx_visible {
                .scroll-element_track {
                    top: -12px;
                }

                .scroll-element_size {
                    top: -12px;
                }
            }
        }
    }
}