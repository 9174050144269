/* --- Container Boxes --- */

.test {
    font-size: 5rem;
    background-color: yellow;
}

.container-box {
    border-radius: 1.6rem;
    box-sizing: border-box;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.05);
    padding: 3rem;
    position: relative;

    &--white {
        background-color: $white;
    }

    &--darkblue {
        background-color: $blue-tint-1;
    }
}

/* --- Text --- */

.title__mask {
    margin: -9rem auto 0 auto;
    position: fixed;
    top: 0;
    width: 100%;

    .title {
        color: $dark-blue;
        font-size: 25rem;
        letter-spacing: -2.7rem;
        line-height: 25rem;
        margin: 0 auto;
        max-width: 174rem;
        opacity: .03;
    }
}

.title__container {
    margin: 0 auto;
    max-width: 120rem;
    text-align: center;
    width: 100%;
}

.title__scale {
    display: inline-block;
    transform-origin: 50% 0;
    -webkit-font-smoothing: antialiased;
    transform: translate3d(0, 0, 0);
}

.title {
    color: $dark-blue;
    font: {
        family: $caveat;
    }
    opacity: .08;
    padding: 0;
}

.section-title {
    color: $dark-blue;
    font: {
        family: $caveat;
        size: 7.5rem;
    };
    letter-spacing: -.4rem;
    line-height: 5rem;
    opacity: .15;
}

.subtitle-big {
    color: $dark-blue;
    font: {
        family: "Muli Black";
        size: 3.2rem;
    }
    line-height: 4rem;
}

.subtitle {
    font:{
        family: $font-application-black;
        size: 1.7rem;
    };
    line-height: 3rem;
}

.color--orange,
.subtitle--orange {
    color: $orange;
}

.color--green,
.subtitle--green {
    color: $green;
}

.color-dark-green {
    color: $green-tint-1;
}

.description {
    font-size: 1.4rem;
    line-height: 2.4rem;
}

.description--grey {
    clear: both;
    color: $grey-tint-6;
}


/* ----- Links ----- */
a {
    color: $dark-blue;
}

%link,
.link {
    color: $dark-blue;
    font: {
        variant-ligatures: none;
        weight: 700;
    }
    letter-spacing: .1rem;
}

%link--white,
.link--white {
    color: $white;
}

%link--orange,
.link--orange {
    color: $orange;
}

%link--green,
.link--green {
    color: $green;
}

%link--grey,
.link--grey {
    color: $grey-tint-4;
}


.error-message {
    color: $red;
    line-height: 1.6rem;
    font-weight: 800;
    margin: 3rem 0;
    text-align: center;
}

/* ----- Buttons ----- */
%button,
.button {
    border: none;
    border-radius: 1.2rem;
    box-sizing: border-box;
    box-shadow: 0 .8rem .75rem rgba(38, 44, 57, .09);
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-family: $muliBlack;
    font-size: 1.3rem;
    line-height: 1.4rem;
    letter-spacing: 0.1rem;
    margin: 3rem 0;
    padding: 1.5rem 2.3rem;
    text-decoration: none;
}

%button-orange,
.button--orange {
    background-color: $orange;
}

%button-green,
.button--green {
    background-color: $green;
}

.button--dark-green {
    background-color: $green-tint-1;
}

.button--dark-blue {
    background-color: $dark-blue;
}

.button--gray {
    background-color: $grey-tint-5;
}

.button--facebook {
    background-color: $blue-tint-2;
}

strong {
    font-weight: 700;
}

.message {
    color: $dark-blue;
    font-family: "Caveat Brush",cursive;
    font-size: 2.25rem;
    line-height: 3.2rem;
    margin: 2rem 0;
    opacity: .2;
}

.line-before {
    color: $grey-tint-5;
    font: {
        size: 1.4rem;
        weight: 400;
    }
    line-height: 2.5rem;
    padding: 0 0 0 2.5rem;
    position: relative;

    &:before {
        background-color: $grey-tint-3;
        content: "";
        height: 0.2rem;
        left: 0;
        position: absolute;
        top: 49%;
        width: 1.4rem;
    }
}

.list {
    margin-bottom: 3rem;

    li {
        margin-bottom: 0.8rem;
        padding: 0 0 0 2.7rem;
        position: relative;

        &:before {
            background-color: $grey-tint-8;
            border-radius: 50%;
            content:"";
            height: 0.8rem;
            position: absolute;
            top: calc(50% - 4px);
            left: -0.2rem;
            width: 0.8rem;
        }

        strong {
            font-weight: 800;
        }

        &.color-dark-green {
            &:before {
                background-color: $green;
            }
        }
    }
}

.menu-tabs {
    clear: both;
    display: flex;
    font: {
        family: $font-application-black;
        size: 1.3rem;
    }
    margin-bottom: 4rem;
    width: 100%;

    li {
        cursor: pointer;
        //flex: 1;
        padding: 0.5rem 0;
        margin-right: 0.5rem;
        text-align: center;

        a {
            color: $dark-blue;
            text-decoration: none;
        }

        &.Active {
            background: {
                image: url("/images/new_design/menu-tabs-background.png");
                size: 100% 100%;
                position: center;
                repeat: no-repeat;
            }
            padding: 0.5rem;

            a {
                color: $white;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.hidden {
    display: none;
}

.loader {
    background-color: $grey-tint-1;
    bottom: 0;
    display: none;
    left: 0;
    margin: 0 auto;
    max-width: 130rem;
    padding: 4rem 0;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 999;
    width: 100%;

    img {
        margin: 0 auto;
    }
}

/* ----- iVox Info (contains general styles for the pages in Footer) ----- */

.ivox-info {
    /* --- Page Subtitles --- */
    .ivox-info__subtitle {
        font: {
            family: $caveat;
            size: 5.1rem;
        };
        letter-spacing: -.3rem;
        margin: 5rem 0 2rem 0;
        opacity: .4;
        width: 100%;
    }

    /* --- Page Descriptions --- */
    .ivox-info__description {
        line-height: 2.1rem;
    }

    /* --- Page Descriptions Grey --- */
    .ivox-info__description--grey {
        color: $grey-tint-4;
    }

    /* --- Page Descriptions List --- */
    .ivox-info__description-list {
        color: $grey-tint-4;
        font-size: 1.3rem;
        line-height: 2.1rem;
        list-style: disc;
        margin: 2rem 0 0 2rem;
    }

    /* --- Page Links --- */
    .ivox-info__link {
        color: $orange;
        font: {
            weight: 700;
            variant-ligatures: none;
        }
        letter-spacing: .2rem;
    }
}