.forget-password__form {
    margin: 0 2rem;

    .Message {
        clear: both;
        color: $grey-tint-6;
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin: 2rem 0 0 0;
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 1.6rem;
        box-sizing: border-box;
        box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.05);
    }
}

@media only screen and (min-width: $screen-sm) {
    .forget-password__form {
        margin: 0 5rem;
    }
}

@media only screen and (min-width: $screen-m) {
    .forget-password-page {
        margin-top: 10rem;
    }

    .forget-password {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .forget-password__form {
        width: 50%;
    }
}
