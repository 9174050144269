.homepage-container-top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 100%;
}

.active-quiz {
    margin-top: 5rem;

    &__question {
        font: {
            family: $font-application-black;
            size: 3.2rem;
        }
        letter-spacing: -0.02rem;
        line-height: 4rem;
    }

    &__description {
        margin-top: 3rem;
    }

    &__button {
        margin: 3rem 0;
    }

    &__footer {
        color: $dark-blue;
        font: {
            family: $caveat;
            size: 2.25rem;
        }
        opacity: .2;
    }
}

.daily-question {
    background-color: $blue-tint-1;
    border-radius: 1.6rem;
    box-sizing: border-box;
    margin-top: 5rem;
    padding: 3rem;
    position: relative;
    width: 100%;

    &__title {
        color: $white;
        font: {
            family: $caveat;
            size: 6.1rem;
        };
        letter-spacing: -.25rem;
        margin-bottom: 2rem;
    }

    &__subtitle {
        color: $green;
        font: {
            family: $caveat;
            size: 4.1rem;
        }
        letter-spacing: -.25rem;
        margin: -3rem 0 0 0;
    }

    &__content {
        box-sizing: border-box;
        color: $white;
        font: {
            size: 2.3rem;
            weight: 800;
        }
        letter-spacing: 1px;
        line-height: 3.1rem;
        padding: 3rem 0 5rem 0;
    }

    &__link {
        box-sizing: border-box;
        display: inline-block;
        font-weight: 400;
        margin: 0 0 1rem 0;
    }
}

.homepage-container-bottom {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 4rem;
    width: 100%;
}

.past-quiz {
    background-color: $white;
    border-radius: 1.6rem;
    box-sizing: border-box;
    box-shadow: 0 .5rem 2rem rgba(0,0,0, .05);
    margin-bottom: 2rem;
    padding: 3rem;
    position: relative;
    width: 100%;

    &__question {
        font: {
            family: $muliBlack;
            size: 2.3rem;
        }
        line-height: 3.1rem;
    }

    &__description {
        line-height: 2.3rem;
        opacity: 0.8;

        span {
            font-weight: 700;
        }
    }

    &__winners-link {
        bottom: 3rem;
        font-family: $muliBlack;
        left: 3rem;
        position: absolute;
    }
}

.business {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 3rem 0;

    &__image {
        float: left;
        margin: 0 0 2rem 0;
        max-width: 50%;

        img {
            margin-right: 3rem;
            max-width: 90%;
        }
    }

    &__title {
        float: left;
        color: $green;
        font: {
            family: $caveat;
            size: 6rem;
        }
        letter-spacing: -.35rem;
        line-height: 5rem;
        margin: 0.5rem 0 0;
        max-width: 50%;
        width: 18rem;
    }

    &__description {
        clear: both;
        display: block;
        margin: 2rem 0 4rem 0;
        width: 100%;
    }

    &__more-info-link {
        bottom: 3rem;
        display: block;
        font-weight: 400;
        letter-spacing: -0.1px;
        margin: 0;
        position: absolute;
    }
}

.homepage-page {
    .active-quiz-prize-description {
        display: none;
    }
}