.active-quiz-container {
    margin: 0;
}

.active-quiz-prize-container {
    align-self: center;
    -webkit-align-self: center;
    color: $dark-blue;
    display: block;
    font-size: 1.3rem;
    line-height: 2.3rem;
    margin: 0 auto;
    position: relative;
    width: 70%;
}

.active-quiz-prize {
    img {
        max-width: 100%;
    }
}

.active-quiz-prize__sticker {
    max-width: 19rem;
    position: absolute;
    right: -3.5rem;
    top: -4rem;
    width: 70%;
}

.active-quiz-prize__image,
.active-quiz-prize-description {
    max-width: 38rem;
    width: 100%;
}

.active-quiz-prize-description {
    margin-top: 3rem;
    text-align: center;

    strong {
        font-weight: 900;
    }
}

#wrapHeader {
    clear: both;
    position: absolute;
    top: 12rem;
    left: 50%;
    transform: translateX(-50%);
    //background-color: rgba(255, 255, 255, 0.3);
    padding: 1rem 5rem;
    width: 100%;
    z-index: 9999;

    &.Header {
        margin: -1.5rem auto 0 auto;
        max-width: 1200px;
        text-align: right;
    }

    h1 {
        color: $dark-blue;
        display: inline-block;
        font-family: $font-application;
        font-size: 1.3rem;
        font-weight: 500;
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        text-align: right;
    }

    .select-input {
        width: 40%;
        float: right;
    }
}