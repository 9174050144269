.page-results {
    .results-container {
        font-size: 1.4rem;
        line-height: 2.3rem;
        margin: 0 auto;
        max-width: 120rem;
    }

    .subtitle-big {
        margin-bottom: 3rem;
    }

    p {
        margin-bottom: 2rem;
        line-height: 2.3rem;
    }

    .results-prize {
        &-image {
            margin: 0 auto 3rem auto;
            max-width: 40rem;
            position: relative;
            width: 100%;

            &:before {
                background-image: url('/images/new_design/quiz-prize-sticker.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                width: 12rem;
                height: 12rem;
                content: "";
                top: -5rem;
                right: -3rem;
                position: absolute;
            }

            img {
                width: 100%;
            }
        }

        &-description {
            color: $grey-tint-5;
            font-size: 1.3rem;
            line-height: 2.3rem;
            margin-bottom: 3rem;
        }
    }

    .results-info {
        .info-sponsor {
            color: $grey-tint-3;
            font: {
                family: $caveat;
                size: 2.3rem;
            }
            margin-bottom: 4rem;
            text-align: center;

            img {
                padding-top: 1rem;
            }
        }
    }

    .results-info2 {
        padding-top: 5rem;
    }

    .info-sondaj {
        font-size: 1.6rem;
        line-height: 2.7rem;
        margin-top: 4rem;
        margin-bottom: 4rem;

        span {
            font-family: $font-application-black;
        }
    }

    .results-details {
        clear: both;
        display: inline-block;
        padding-top: 8rem;
        width: 100%;
    }

    .results-raport {
        &-inner {
            margin: 0 auto;
            max-width: 100.2rem;
        }

        .raport-row {
            background-image: url("/images/new_design/image-circle.png");
            background-repeat: no-repeat;
            background-position: 52% 50%;
            background-size: contain;
            margin-bottom: 5rem;
            padding-top: 6rem;
            text-align: center;

            .raport-info {
                display: inline-block;
            }

            div {
                clear: both;
                display: block;
                margin: 0 auto;
            }

            p {
                display: block;
                text-align: left;

                span {
                    display: block;
                    font: {
                        family: $font-application-black;
                        size: 3.7rem;
                    }
                    line-height: 1;
                    padding-bottom: 1rem;
                }

            }

            img {
                margin-bottom: 2rem;
                max-width: 100%;
            }

            &-school {
                background-position: 45% 50%;
            }

            &-job {
                padding-bottom: 6rem;
                padding-top: 0;
                background-position: 15% 50%;
            }
        }
    }

    .rezultate-link {
        font-size: 1.6rem;
        padding: 8rem 0;

        a {
            font-weight: 900;
        }
    }

    .wrap {
        clear: both;
        display: inline-block;
        width: 100%;
    }

    .placeholder {
        float: left;
        margin-right: 3rem;
    }

    .message {
        margin: 8rem 0 3rem 0;
    }
}