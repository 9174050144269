.daily {
    max-width: 1200px;
    margin: 0 auto 4rem auto;
}

.daily-question {

    &-page__question {
        margin-bottom: 2rem;
    }

    &__answered {
        color: $dark-blue;
        font: {
            size: 2.3rem;
            weight: 900;
        };
        line-height: 1.25;
    }

    &__form {
        margin-left: 1rem;

        label {
            font: {
                family: $font-application-black;
                size: 1.6rem;
            }
            text-transform: capitalize;
        }
    }

    &__comments {
        clear: both;
        overflow: hidden;
        margin: 0 auto;
        max-width: 1200px;
        padding: 10px 0 0 0;
        text-align: center;
    }

    &__msg {
        font: {
            weight: 400;
        }
        margin-bottom: 3rem;
        margin-left: 1rem;
        margin-top: 3rem;
    }

    &__button-send {
        border: none;
        margin-top: 2rem;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
    }

    &-page {
        .fb-like {
            margin-top: 0;
            margin-left: 1rem;
        }
    }

    &__monthly-quiz-redirect {
        font-size: 1.5rem;
        margin: 2rem 0 2rem 1rem;
    }

    &__monthly-quiz-redirect__link {
        color: $orange;
        font-size: 1.5rem;
        font-weight: 800;
    }
}

.results {
    clear: both;
    display: block;
    max-width: 1200px;
    margin: 0 auto;
}

.results__subtitle {
    font-size: 2.3rem;
}

.results__description {
    font-size: 1.3rem;
    line-height: 2.3rem;
    margin: 2rem 0;

    p {
        line-height: 2.3rem;
    }
}

.charts-container {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 100%;
}

.chart {
    width: 75%;
    margin: 2rem 0;
}

.chart__info,
.chart__nranswers {
    font-size: 1.3rem;
    padding: 3rem 0 2rem 0;
    text-align: center;
}

.chart__nranswers {
    padding-top: 0rem;
}

.daily-question-archive {
    .sponsor {
        float: right;
        display: inline-block;
        margin-bottom: 3rem;
        margin-top: 3rem;
        text-align: center;

        .section-title {
            font-size: 6rem;
        }

        img {
            max-width: 100%;
            margin: 1rem auto 2rem auto;
        }

        p {
            line-height: 2.3rem;
            text-align: left;
        }
    }

    .daily {
        p {
            word-wrap: break-word;
            width: auto;
        }

        &.daily-sponsor {
            display: inline-block;
            box-sizing: border-box;
            padding: 0 3rem;
            width: auto;
        }

        ul {
            font-weight: 700;
        }
    }

    .daily-question-descrioption {
        display: inline-block;
        margin-bottom: 3rem;
        width: auto;

        p {
            word-wrap: break-word;
            line-height: 2.3rem;
        }
    }

    .charts-container {
        clear: both;
    }

    .arhiva-link {
        text-align: left;
        font-size: 1.3rem;
        margin: 3rem 0;
    }
}

.daily-statistic {
    .sponsor {
        margin-bottom: 3rem;

        a {
            display: inline-block;
            padding: 3rem 0;
        }
    }

    .chart-wrapper {
        background-color: $white;
        box-sizing: border-box;
        border-radius: 1.6rem;
        padding: 1.5rem;
        margin: 1rem 0 5rem 0;
    }

    .nr-answers {
        margin-top: -3rem;
        font-size: 1.3rem;
        margin-bottom: 5rem;
        text-align: center;
    }
}


