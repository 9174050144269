/***** FORM QUESTIONS *****/
.question-wrapper {
    clear: both;
    display: inline-block;
    margin-bottom: 1rem;
    width: 100%;
}

.form-group,
.line {
    margin-bottom: 1rem;
}

.form-group {
    label {
        display: inline-block;
    }
}

.line {
    &-row {
        clear: both;
        display: block;
        padding-top: 1rem;
        width: 100%;
    }
}

.question {
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 1.5;
    margin-bottom: 4rem;
    max-width: 120rem;
    width: 100%;
}

.option-image {
    display: inline-block;
    padding-left: 1rem;
}

.question-label {
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 1.5;
    text-transform: capitalize;
}

/***** FORM *****/

.label {
    background: none;
    display: inline;
    font-weight: 700;
    font-size: 1.9rem;
    padding: .5rem 0 0 0;
}

.lbl,
.label-dropdown,
.label-text-input {
    display: block;
    font-family: $muliBlack;
    font-size: 1.6rem;
    line-height: 1.5;
}

.lbl {
    margin-bottom: 1rem;
}

.label-text-input {
    margin: 3rem 0 1rem;
}

/*** Dropdown Input ***/

.label-dropdown {
    margin: 3rem 0 1rem;
}

.input-dropdown-wrapper {
    clear: both;
    position: relative;

    &:after {
        width: 10px;
        height: 6px;
        background: url("../../../images/new_design/icon-arrow-down.png") no-repeat right top;
        content: "";
        display: block;
        position: absolute;
        pointer-events:none;
        right: 2rem;
        top: 2rem;
    }
}

.input-dropdown {
    background-color: $white;
    border: 0.3rem solid $grey-tint-3;
    box-sizing: border-box;
    color: $dark-blue;
    cursor: pointer;
    display: block;
    font-family: $font-application;
    height: 4.8rem;
    line-height: 4.8rem;
    padding: 0 2rem;
    width: 100%;
}

/*** Text Input ***/
textarea,
input,
select {
    &:focus {
        outline: none;
    }
}

select {
    /*for firefox*/
    -moz-appearance: none;
    /*for chrome*/
    -webkit-appearance:none;

    &::-ms-expand {
        display: none;
    }
}

.text-input {
    border: 0.3rem solid $grey-tint-3;
    box-sizing: border-box;
    color: $dark-blue;
    font-family: $font-application;
    height: 4.8rem;
    line-height: 4.8rem;
    display: block;
    padding: 0 2rem;
    width: 100%;

    &:focus {
        color: rgba(72, 53, 56, 0.4);
    }
}

/*** Radio Input ***/

.input-radio {
    display: none;
}

.label-radio {
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin: -1rem 0 0 -1rem;
    min-height: 1.9rem;
    padding: 1rem 1rem 1rem 4rem;
    position: relative;

    &:before {
        background-color: $white;
        border: 3px solid $grey-tint-7;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 2rem;
        left: 1rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        position: absolute;
        vertical-align: middle;
        width: 2rem;
    }

    &:hover {
        background-color: $grey-tint-2;
    }
}

.input-radio:checked + .label-radio:before {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

/*** Checkbox Input ***/

.input-checkbox {
    display: none;
}

.label-checkbox {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.9rem;
    margin: -1rem 0 0 -1rem;
    min-height: 1.9rem;
    padding: 1rem 1rem 1rem 4rem;
    position: relative;

    &:before {
        background-color: $white;
        border: 3px solid $grey-tint-7;
        box-sizing: border-box;
        content: '';
        cursor: pointer;
        display: inline-block;
        height: 2rem;
        left: 1rem;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
        position: absolute;
        vertical-align: middle;
        width: 2rem;
    }

    &:hover {
        background-color: $grey-tint-2;
    }
}

.input-checkbox:checked + .label-checkbox:before {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

/*** Checkbox Input Table ***/

.form-group-checkbox {
  display: inline-block;
}

.input-checkbox-table {
  display: none;
}

.label-checkbox-table {
  span {
    display: none;
  }
}

.input-checkbox-table + .label-checkbox-table:before {
  background-color: $white;
  border: 2px solid $dark-blue;
  border-radius: 25%;
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: 1.5rem;
  text-align: center;
  margin: -.5rem 1rem 0 0;
  vertical-align: middle;
  width: 1.5rem;
}

.input-checkbox-table:checked + .label-checkbox-table:before {
  background-color: $dark-blue;
}

/*** Radio Input Table ***/

.form-group-radio {
  display: inline-block;
}

.input-radio-table {
  display: none;
}

.label-radio-table {
  span {
    display: none;
  }
}

.input-radio-table + .label-radio-table:before {
  background-color: $white;
  border: 2px solid $dark-blue;
  border-radius: 25%;
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: 1.5rem;
  text-align: center;
  margin: -.5rem 1rem 0 0;
  vertical-align: middle;
  width: 1.5rem;
}

.input-radio-table:checked + .label-radio-table:before {
  background-color: $dark-blue;
}

/*** Select Input ***/

.select-input {
    background: $white url("../../../images/new_design/select-arrow.png") no-repeat right 40%;
    border: 0.3rem solid $grey-tint-3;
    border-radius: 0;
    color: $dark-blue;
    font-size: 1.3rem;
    padding: 1.5rem 2rem;  
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
}

/*** Textarea Input ***/

.textarea-input {
    border: 0.3rem solid $grey-tint-3;
    box-sizing: border-box;
    color: $dark-blue;
    display: block;
    font-family: $font-application;
    font-weight: 400;
    height: 10rem;
    max-width: 100%;
    min-width: 100%;
    padding: 1.5rem 2rem;
    text-decoration: none;
    //resize: none;
    width: 100%;

    &:focus {
        color: rgba(72, 53, 56, 0.4);
    }
}

.question-col {
    .label-radio,
    .label-checkbox {
        &:hover {
            padding: 1rem 1rem 1rem 4rem;
        }
    }
}

iframe {
    max-width: 100%;
}

/*** Errors ***/

.err_message,
.errors {
    color: $red;
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 800;
    margin: 1rem 0;
}