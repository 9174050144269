.footer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    margin: 6rem 0;

    .container {
        max-width: 1200px;
    }
}

.additional-info {
    width: 100%;

    li {
        padding-bottom: 1.5rem;
    }

    &__link {
        color: $dark-blue;
        font: {
            size: 1.3rem;
            variant-ligatures: none;
        }
        letter-spacing: 1px;
        opacity: 0.4;
    }
}

.copyright {
    line-height: 2.3rem;
    margin-bottom: 3rem;

    &-text {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    &-additional-info {
        color:  $dark-blue;
        font-size: 1.3rem;
        line-height: 2.3rem;
        max-width: 30rem;
        opacity: 0.4;
    }
}
