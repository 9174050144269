/************* General Styles *************/
html, body {
    background-color: $grey-tint-1;
    color: $dark-blue;
    font-family: $font-application;
    font-weight: 400;
    font-size: 62.5%; // Making font size 10px
    overflow-x: hidden;
}

main {
    padding-top: 0;
}

%container,
.container {
    font-size: 1.4rem;
    letter-spacing: .09rem;
    margin: 0 auto;
    max-width: 1300px;
    position: relative;
    z-index: 1;
    width: 90%;
}

header {
    background: linear-gradient(0deg, $grey-tint-1, $grey-tint-1 25%, #DADADA);

    .container {
        max-width: 1200px;
        z-index: 2;
    }
}

.logo {
    margin-top: 2.6rem;
}

.clear {
    clear:both;
    margin: 0;
    padding: 0;
    width: 100%;
}

p {
    font-size: 1.4rem;
    line-height: 1;
}

.space-b-22 {
    margin-bottom: 2.2rem;
}

.hand {
    cursor:pointer;
}

.noscript {
    text-align: center;
}

%display-inline,
.display-inline {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -webkit-flex-direction: row;
}

%display-columns,
.display-columns {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
}

%align-items,
.align-items {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}