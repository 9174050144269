.navigation-bar {
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1rem 0;
}

#menu-toggle {
    span {
        background-color: $dark-blue;
        box-sizing: border-box;
        display: block;
        height: .3rem;
        margin: .6rem 0;
        width: 3rem;
    }
}

.main-navigation {
    background-color: #333E50;
    box-shadow: 1rem 0 1.5rem rgba(0,0,0,0.2);;
    font-size: 1.5rem;
    height: 100%;
    left: 0;
    max-width: 25rem;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition: .25s ease all;
    width: 80%;
    z-index: 10;
}

.mobile-navigation {
    margin: 3rem 2rem;
}

.mobile-navigation__toggle {
    color: $white;
    box-sizing: border-box;
    display: inline-block;
    font-weight: 800;
    padding: .75rem;
}

.mobile-navigation__toggle--active {
    background: {
        image: url("/images/new_design/navigation-hover-background.png");
        position: center;
        repeat: no-repeat;
        size: 100% 100%;
    }
    color: $white;
}

.user-menu {
    display: block;
    font-size: 1.3rem;
    margin: 0 1.75rem;

    .palceholder {
        &-desktop {
            display: none;
        }

        &-mobile {
            display: inline-block;
        }
    }

    li {
        a {
            box-sizing: border-box;
            color: $white;
            display: inline-block;
            font: {
                family: $font-application-black;
                size: 1.4rem;
            }
            letter-spacing: 0.1rem;
            margin-right: 1.2rem;
            padding: 1rem;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
        }

        &.user-details {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;


            img {
                padding-left: 1rem;
                height: 16px !important;
            }

            a {
                color: $grey-tint-5;
                font: {
                    family: $caveat;
                    size: 1.8rem;
                    weight: 700;
                }
                opacity: 1;
                margin: 0;
                padding-left: 1rem;
                width: auto;
            }
        }

        &.admin {
            a {
                opacity: 1;
            }
        }

        &.user-points {
            a {
                text-decoration: none;
            }
        }

        &:last-child {
            a {
                margin-right: 0;
                padding-right: 0;
            }
        }


    }


}

.user-menu--hidden {
    display: none;
}

.user-account-mobile {
    list-style: none;
    margin-left: 3.5rem;

    li {
        a {
            padding: 1rem 0;
            margin-right: 0;
        }
    }
}

.navigation {
    display: block;
    font-weight: 400;
    margin: 0 1.75rem;

    li {
        a {
            color: $white;
            display: inline-block;
            font: {
                family: $font-application-black;
                size: 1.4rem;
            }
            letter-spacing: 1px;
            padding: 1.2rem;
            text-transform: uppercase;
            text-decoration: none;
            width: 100%;
        }

        &:last-child {
            a {
                padding-right: 0;
            }
        }
    }
}

.navigation--hidden {
    display: none;
}

.main-navigation--hidden {
    left: -100%;
}