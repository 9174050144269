.istoric-navigation {
    box-sizing: border-box;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 1.9rem;
    font-weight: 800;
    letter-spacing: .05rem;
    //padding-top: 2rem;
    margin: 2rem auto 0 auto;
}

.istoric-navigation-link {
    padding: 1.4rem 1.2rem;
    font-family: $muliBlack;
    font-size: 2.3rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;

    a {
        color: $dark-blue;
        text-decoration: none;
    }
}

.istoric-navigation-link-active {
    opacity: 1;
    background: {
        image: url("/images/new_design/navigation-hover-background.png");
        size: 100% 100%;
        position: center;
        repeat: no-repeat;
    }

    a {
        color: $grey-tint-1;
    }
}

.past-quizzes-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 2rem;
    width: 100%;
}

.past-quiz-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 2.5rem;
    position: relative;
    width: 100%;
}

.past-quiz__image-container {
    box-sizing: border-box;
    margin-bottom: 3rem;
    position: relative;
    width: 100%;
}

.past-quiz__image {
    align-self: center;
    box-sizing: border-box;
    margin: 0;
    text-align: center;

    img {
        height: auto;
        max-width: 100%;
        width: 100%;
    }
}

.past-quiz__question {
    font-size: 2.3rem;
}

.past-quiz__description {
    padding: 2rem 0 5rem;

    strong {
        font-weight: 800;
    }
}

.past-quiz__winners-link {
    box-sizing: border-box;
    display: inline-block;
    bottom: 3rem;
    left: 3rem;
    position: absolute;
}

.past-questions-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 2rem;
    width: 100%;
}

.past-question-container {
    margin-top: 2rem;
    position: relative;
    width: 100%;
}

.past-question {
    font: {
        family: $muliBlack;
        size: 2.3rem;
    }
    line-height: 3.1rem;
    margin-bottom: 3rem;
}

.past-question__results {
    box-sizing: border-box;
    display: inline-block;
    bottom: 3rem;
    left: 3rem;
    position: absolute;
}
