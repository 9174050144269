/*----------------------------------
    #Anti-Spam
----------------------------------*/
.anti-spam {
    margin-top: 3rem;
    width: 100%;

    &__title {
    }

    &__subtitle {
        margin-bottom: 2rem;
    }

    &__description {
        box-sizing: border-box;
        color: $grey-tint-5;
        padding: 3rem 0 3rem 0;

        span {
            display: block;
            padding-top: 2rem;
        }
    }

    &__image {
        text-align: center;

        img {
            margin: 0 auto;
        }
    }
}