@import "../config/fonts";
@import "../config/variables";

.feedback-container {
    margin: 0 2rem;
}

@media only screen and (min-width: $screen-sm) {
  .feedback-container {
      margin: 0 5rem;
  }
}

@media only screen and (min-width: $screen-m) {
    .feedback-page {
        .container {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
        }
    }

    .feedback-container {
        width: 50%;
    }
}

@media only screen and (min-width: $screen-l) {
    .feedback-page {
        .anti-spam {
            margin-top: 0;
        }
    }

    .feedback-container {
        margin-top: 3rem;
    }
}