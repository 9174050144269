.points__subtitle {
    font-family: $font-application-black;
    margin: 2rem 0 3rem;
    max-width: 120rem;
}

.disclaimer__image {
    display: block;
    margin: 0 auto 3rem auto;
    max-width: 35rem;
    width: 100%;
}

.disclaimer__description {
    color: $grey-tint-5;
    font-size: 1.2rem;
    font-style: italic;
}

.points {
    margin: 0;
}

.points__description {
    margin: 0 0 2rem;
}

.points__description-list {
    clear: both;
    display: inline-block;
    margin-top: 3rem;
    width: 100%;
}

.extra-chance {
    margin: 0;
}

.extra-chance__title {
    margin-top: 5rem;
}

.extra-chance__subtitle {
    margin: -1rem 0 0;
}

.extra-chance__description {
    margin: 3rem 0 0;
}

.extra-chance__button {
    border: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    letter-spacing: 0;
    margin: 2rem 0 0;
    padding: 1.8rem 2rem;
    width: auto;
    white-space: nowrap;
}

.vouchers__title {
    margin-top: 5rem;
}

.vouchers__subtitle {
    margin: 0;
}

.vouchers__description {
    margin: 2.5rem 0;
}

.voucher-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 100%;
}

.voucher {
    background-color: $white;
    border-radius: 1.6rem;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.05);
    box-sizing: border-box;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 2rem;
    padding: 2rem;
    position: relative;
    width: 100%;
}

.voucher__spacer {
    display: block;
    left: 0;
    height: 2.6rem;
    position: absolute;
    top: 9rem;
    width: 100%;

    span {
        background-image: url("/images/new_design/icon-bullet.png");
        background-position: 0 50%;
        background-repeat: repeat-x;
        display: block;
        height: 2.6rem;
        margin: 0 2.1rem;
        position: relative;

        &:before,
        &:after {
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            content: "";
            height: 2.6rem;
            position: absolute;
            top: 0;
            width: 2.1rem;
        }

        &:before {
            background-image: url("/images/new_design/icon-arrow-right.png");
            left: -2.1rem;
        }

        &:after {
            background-image: url("/images/new_design/icon-arrow-left.png");
            right: -2.1rem;
        }
    }
}

.voucher__logo {
    align-self: center;
    height: 60px;
    margin-bottom: 4rem;

    img {
        width: 100%;
        max-height: 60px;
        max-width: 20rem;
    }
}

.voucher__discount {
    align-self: center;
    color: $green;
    font: {
        family: $caveat;
        size: 3.3rem;
    }
    justify-content: center;
    line-height: 5rem;
    margin: 2rem 0;
    letter-spacing: -0.20rem;
    text-align: center;

    span {
        font: {
          size: 9.5rem;
        };
        display: block;
    }
}

.voucher__more-info-link {
    align-self: center;
    margin: 1.3rem 0 0.5rem;
    text-align: center;
}

.voucher__button-buy {
    align-self: center;
    border: none;
    padding-bottom: 1.8rem;
    padding-top: 1.8rem;
    margin: 1rem 0 0;
}

.vouchers__footer {
    color: $grey-tint-5;
    font-size: 1.2rem;
    font-style: italic;
    margin: 3rem 0;
}

/*----------------------------------
    #Detalii Cupon
----------------------------------*/

.coupon-info-container {
    .detalii-cupon {
        .voucher {
            text-align: center;
        }

        .return {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: - 6.8rem;
        }
    }
    .DescriereCupon, //old template
    .descriere-cupon {
        line-height: 2.3rem;
        margin-top: 10rem;

        h5 {
            margin-bottom: 3rem;
        }

        .ovservatii {
            color: $grey-tint-5;
            padding: 6.8rem 0 8rem 0;

            strong,
            span {
                display: block;
            }
        }

        ul.info-puncte {
            color: $dark-blue;
            font: {
                family: $font-application-black;
                size: 2.3rem;
            }
            line-height: 3rem;
            margin-bottom: 3rem;
        }

        ul.sugestii {
            li {
                .link {
                    font-weight: 400;
                }
            }
        }
    }

    //old Template
    .DetaliiCupon {
        > div:first-child {
            background-color: $white !important;
            background-position: center 120px !important;
            border-radius: 1.6rem;
            box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.05);
            box-sizing: border-box;
            display: inline-block;
            margin-top: 2rem;
            padding: 2rem;
            position: relative;
            text-align: center;
            width: 100%;

            a:not(.button) {
                display: block;
            }

            &:before,
            &:after {
                content: "";
                height: 2.6rem;
                top: 8rem;
                position: absolute;
            }


            &:before {
                background-image: url(/images/new_design/icon-bullet.png);
                background-position: 0 50%;
                background-repeat: repeat-x;
                left: 2.1rem;
                width: calc(100% - 4.2rem);
            }

            &:after {
                background-image: url(/images/new_design/icon-arrow-right.png), url(/images/new_design/icon-arrow-left.png);
                background-position: 0 top, 100% top;
                background-repeat: no-repeat, no-repeat;
                left: 0;
                width: 100%;
            }

            .link {
                margin-top: 8rem;
                margin-bottom: 3rem;
            }
        }

        .button {
            margin-top: 12rem;
        }

        .Return {
            bottom: 0;
            left: 0;
            margin-bottom: -6rem;
            position: absolute;
            width: 100%;
        }
    }

    .DescriereCupon {
        line-height: 2.3rem;

        h5 {
            font-family: $font-application-black;
            font-size: 2.3rem;
        }

        p {
            color: $grey-tint-5;
            padding: 6rem 0;
        }
    }
}