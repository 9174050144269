

.account-navigation-container {
    display: none;
}

.account-container {
    .title__container {
        width: 100%;
    }
}

/*----------------------------------
    #Sumar
----------------------------------*/

.account-summary__title {
    font: {
        size: 2.3rem;
    }
    margin-bottom: 3.2rem;
}

.last-quiz__message {
    font-weight: 900;
    line-height: 2.3rem;
    margin-bottom: 3rem;
}

.last-quiz__prize {
    display: block;
    margin: 1rem auto 2rem;
    width: 50%;
}

.last-quiz__report {
    color: $dark-blue;
    display: block;
    font: {
        weight: 900;
    }
    text-decoration: none;
    margin-bottom: 1rem;
}

.last-quiz__date {
    margin-bottom: 3.2rem;
}

.button--account {
    margin-top: 0;
    padding-bottom: 1.8rem;
    padding-top: 1.8rem;
}

.account-summary__daily-question {
    margin-top: 3rem;
}

.daily-question__message {
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 2.3rem;
    margin-left: 2rem;
}

.daily-question__question {
    font: {
        size: 1.5rem;
        weight: 800;
    }
    line-height: 1.5;
    margin-left: 2rem;
}

.account-summary__extra-details {
    margin-top: 3rem;
}

.extra-details__info,
.extra-details__list {
    line-height: 1.6;
    margin: 0;
}

.extra-details__info {
    color: $grey-tint-5;
    margin-bottom: 3rem;
}

.extra-details__list {
    list-style: none;

    li a {
        color: $dark-blue;
    }
}

.account-summary__facebook-plugin {
    margin-top: 5rem;
    width: 100%;
}

/*----------------------------------
    #Sondaje
----------------------------------*/

section.quizes {
    .quizes__description {
        color: $grey-tint-4;
        line-height: 1.5;
    }

    .past-quiz__question {
        a {
            text-decoration: none;
        }
    }

    .past-quiz__month {
        font: {
            size: 1.2rem;
        }
        letter-spacing: 0rem;
    }

    .quiz__tagline {
        font-weight: 800;
        color: $grey-tint-4;
        box-sizing: border-box;
        line-height: 1.5;
        margin-top: 1rem;
    }

    .past-quiz__description {
        margin-bottom: 5.5rem;
    }

    .quiz__participation-code {
        font-size: 1.2rem;
        margin-top: 1.5rem;
    }

    .past-quiz__winners-link {
        font-family: $font-application;
        font-weight: 400;
    }

    .quizes__message {
        font-size: 1.6rem;
        font-weight: 700;
        margin-top: 3rem;
    }
}

/*----------------------------------
    #Points
----------------------------------*/

.points__details {
    line-height: 1.5;
    margin: 2rem 0 3rem 0;
}

.points__list {
    list-style: none;
    margin-bottom: 4.5rem;

    a {
        font-weight: 400;
        line-height: 1.6;

    }
}

.points__list--facebook-login {
    color: $grey-tint-5;
    line-height: 2.3rem;
    margin-bottom: 4rem;

    a {
        color: $grey-tint-5;
        font-weight: 400;
    }
}

.points__table {
    color: $grey-tint-5;
    line-height: 1.5;
    margin: 0 0 4rem 0;
    width: 100%;
}

.points__table__row {
    border-bottom: 1px solid $grey-tint-2;
    box-sizing: border-box;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 1.5rem 0;
    width: 100%;

    &:last-child {
        border: 0;
    }
}

.points__name {
    display: none;
}

.points__value {
    display: inline-block;
    width: 25%;
}

.points__date {
    display: inline-block;
    width: 20%;
}

.points__extra-details {
    display: inline-block;
    text-align: right;
    width: 55%;

    a {
        color: $grey-tint-5;
        display: block;
    }
}

.points__title {
    clear: both;
    display: inline-block;
    font-size: 2.3rem;
    margin-bottom: 3rem;
}

/*----------------------------------
    #Setari / Profil
----------------------------------*/

.account-settings {
    margin-top: 3rem;
    margin-bottom: 2rem;

    .label-text-input,
    .label-dropdown {
        margin-top: 2rem;
    }

    fieldset {
        margin-bottom: 4rem;
    }

    #fieldset-messagesgroup {
        legend {
            margin-bottom: 3rem;
        }
    }

    #fieldset-fbgroup,
    #fieldset-izgroup,
    #fieldset-vcgroup {
        dt {
            display: none;
        }
    }

    #fieldset-info {
        .form-group:nth-child(4),
        .form-group:nth-child(5),
        .form-group:nth-child(6) {
            display: inline-block;

            label {
                display: block;
            }

            .input-date {
                display: inline-block;
                box-sizing: border-box;
                width: 9rem;
            }
        }

        .form-group:nth-child(5) {
                margin-left: -2.6rem;
        }
    }

    legend {
        clear: both;
        display: inline-block;
        font: {
            family: $font-application-black;
            size: 2.3rem;
        }
        margin-bottom: 1rem;
    }

    .Button {
        @extend %button;
        @extend %button-green;
    }

    .error {
        color: $red;
        font-size: 1.3rem;
        font-weight: 800;
        margin-bottom: 1rem;
        margin-top: 3rem;
    }
}

/*----------------------------------
    #Invita prieteni
----------------------------------*/

.invites-links {
    font-size: 1.5rem;
    display: flex;

    .line {
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

.invites-description {
    margin-bottom: 3rem;
}

/*----------------------------------
    #Foloseste puncte
----------------------------------*/

.coupon-info {
    p {
        margin-bottom: 3rem;
    }

    ul li a {
        display: inline-block;
        margin-top: 1rem;
        line-height: 1.5;
    }
}

section.extra-chance {
    padding: 0;
}

.coupon-suggest {
    margin-top: 4rem;
}

.coupon-shop {
    margin-top: 3rem;
}

/*----------------------------------
    #Cupoane
----------------------------------*/
.coupons {
    .subtitle {
        clear: both;
        display: inline-block;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    table.Account {
        line-height: 2.3rem;
        max-width: 100%;
        width: 100%;

        .puncte {
            text-align: center;
            span {
                display: block;
                font-weight: 700;
            }
        }

        .detalii {
            text-align: right;
        }
    }
}

.quiz-result {
    .interpretation {
        border-top: 1px solid $grey-tint-2;
        border-bottom: 1px solid $grey-tint-2;
        margin-top: 5rem;
        padding-bottom: 2.5rem;
        padding-top: 2.5rem;
        margin-bottom: 5rem;
    }
}

.account-banner {
    h4 {
        border-bottom: 1px solid $grey-tint-2;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    .banner-box {
        display: flex;
        border-bottom: 1px solid green;
        width: 100%;
        margin-bottom: 5rem;
    }

    .banner-flash,
    .fieldset-line {

    }
}

.info_copy {
    display:none;
    float:right;
    padding-top: 5px;
}


/*----------------------------------
    #Șterge cont
----------------------------------*/

.account-sterge-cont {
    .description {
        margin-bottom: 3rem;
    }

    h4 {
        margin-bottom: 1rem;
    }

    a {
        margin-right: 1rem;
    }
}

