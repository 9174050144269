.login {
    font-size: 1.3rem;
    margin: 3rem 2rem 0 2rem;
}

.login__form {
    margin-bottom: 3.5rem;
    margin-top: 1.5rem;
}

.login__button {
    display: inline-block;
    margin-right: 0.5rem;
    padding: 1.5rem;
    -webkit-appearance: none;
}

.login__password-recovery {
    display: inline-block;
    margin: 1rem 0 0 0;
    text: {
        align: center;
    }
}

.login__facebook-login {
    padding: 1.5rem;
}

label, input {
    display: block;
}