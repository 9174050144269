.press-articles-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin-top: 2rem;
    width: 100%;
}

.press-article {
    margin-top: 3rem;
    position: relative;
    width: 100%;
}

.press-article__source {
    color: $grey-tint-4;
    font-size: 1.3rem;
    padding: 0 0 2rem 0;
}

.press-article__title {
    font: {
        family: $font-application-black;
        size: 2.3rem;
    }
    line-height: 3.1rem;
    padding: 3rem 0 0;
}

.press-article__description {
    padding: 3rem 0 8rem;
    word-break: break-word;
}

.press-article__more {
    box-sizing: border-box;
    display: inline-block;
    bottom: 3rem;
    left: 3rem;
    position: absolute;
}