.flash-dialog {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 99999;
    opacity:0;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
    pointer-events: none;

    &.flash-dialog--show {
        opacity:1;
        pointer-events: auto;
        transition: opacity 400ms ease-in;
        -webkit-transition: opacity 400ms ease-in;
    }

    &.flash-dialog--hide {
        opacity: 0;
        pointer-events: none;
        transition: opacity 800ms ease-out;
        -webkit-transition: opacity 800ms ease-out;
    }

    div {
        background: $white;
        border-radius: 0;
        margin: 20% auto;
        opacity: 1;
        padding: 5rem;
        position: relative;
        text-align: center;
        width: 400px;
        font-size: 1.3rem;
        color: $dark-blue;

        .flash-dialog__close {
            border-radius: 0;
            cursor: pointer;
            color: $dark-blue;
            font-size: 2.4rem;
            font-weight: bold;
            line-height: 30px;
            position: absolute;
            right: 1rem;
            text-align: center;
            text-decoration: none;
            top: 1rem;
            width: 30px;
        }
    }
}

