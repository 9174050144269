.monthly-quiz-title {
    font: {
        family: $caveat;
    }
}

.monthly-quiz-container-top {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin: 0 2rem;
}

.monthly-quiz-question {
    color: $orange;
    font-size: 4.9rem;
    font-weight: 900;
}

.daily-question-message,
.monthly-quiz-message {
    color: $dark-blue;
    font: {
        family: $caveat;
        size: 2.25rem;
    }
    margin: 2rem 0;
    opacity: .2;
}

.monthly-quiz-description {
    color: $dark-blue;
    font-size: 1.3rem;
    font-weight: 900;
    line-height: 2.5rem;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}

//.list,
.monthly-quiz-additional-info {
    margin: 3rem 0 0 0;
}

.monthly-quiz__button {
    margin-top: 2rem;
}

.fb-like {
    display: block;
    margin-top: 5rem;
    width: 100%;
}

.monthly-quiz-container-bottom {
    flex-direction: column;
    -webkit-flex-direction: column;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 5rem;
    padding-bottom: 2rem;
}

.client-information {
    font-size: 1.3rem;
    line-height: 2rem;
    margin-top: -1rem;
}

.client-information-title {
    font: {
        family: $font-application-black;
        size: 2.3rem;
    }
    letter-spacing: 0.1rem;
    line-height: 3rem;
    margin: 0 2rem;
}

.client-information-description,
.client-information-aditional-info {
    color: $dark-blue;
    line-height: 2.3rem;
    margin: 3rem 2rem 0 2rem;

    a {
        color: $dark-blue;
        font-weight: 700;
    }

    b,
    strong {
        font-weight: 700;
    }
}

.quiz-sponsor {
    color: $dark-blue;
    font: {
        family: $caveat;
        size: 7.5rem;
    }
    line-height: 5.5rem;
    letter-spacing: -0.4rem;
    opacity: .1;
}

.client-image {
    margin-top: 3rem;
    width: 100%;

    img {
        width: 100%;
    }
}

.monthly-quiz-container-middle {
    margin: 0 2rem;

    .participate {
        h2 {
            color: $green;
            font: {
                family: $caveat;
                size: 3.9rem;
            }
            letter-spacing: -.25rem;
        }
    }
}