.signup {
    margin: 3rem 0 5rem 0;

    input {
        &[type="submit"] {
            //border: 0;
        }

        &#password2 {
            margin-bottom: 3rem;
        }
    }

    .label-text-input {
        clear: both;
        float: left;
        line-height: 2.3rem;
        margin-top: 2.4rem;
    }

    .form-group {
        margin-bottom: 0;

        label {
            &:hover {
                background-color:transparent;
            }
        }
    }

    .form-group-info {
        margin: 2rem 0;

        label {
            padding-bottom: 0;
            padding-top: 0;
        }

        .show-more-info {
            color: $grey-tint-6;
            display: inline-block;
            line-height: 2.3rem;
            margin-left: 3rem;
            text-align: right;
        }

        .more {
            clear: both;
            line-height: 2.3rem;
            margin-bottom: 2rem;
            padding-left: 3rem;
            width: 100%;
        }
    }
}

.signup__button {
    display: block;
    -webkit-appearance: none;
}

.capcha {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;

    .form-group {
        width: 100%;
    }

    .capcha-image-wrapper {
        width: 100%;
    }

    &__image {
        margin-top: 2rem;
        max-width: 30rem;
        width: 60%;
    }

    &__refresh {
        box-sizing: border-box;
        float: left;
        text: {
            align: center;
        }
        margin-top: 3rem;
        width: 40%;

        span {
            border-bottom: 2px solid rgba(38, 44, 57, 0.5);
        }
    }
}

.cont-details {
    legend {
        color: $dark-blue;
        font-size: 15.5rem;
        opacity: .08;
        letter-spacing: -1rem;
        font-family: $caveat;
    }

    #sex-label {
        margin-top: 3rem;

        label {
            font-weight: 800;
        }
    }

    #sex-label + .form-group {
        .label-text-input {
            margin: 0;
        }

    }
}