.contact-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0 2rem;
}

.link-error {
    display: block;
}

.map {
    box-sizing: border-box;
    margin: 0;
    padding: 3rem 2rem 0;
}

.map-location {
    display: block;
    margin-top: 2rem;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .contact-form {
        margin: 0;
        padding: 0 5rem;
    }

    .map {
        margin: 0;
        padding: 3rem 5rem 0;
    }
}

@media only screen and (min-width: 1024px) {
    .contact-page {
        .container {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-justify-content: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;
        }
    }

    .contact-form {
        width: 50%;
    }

    .map {
        padding-top: 5.5rem;
        width: 50%;
    }
}