/*----------------------------------
    #ThankYou
----------------------------------*/

.thankyou {
    //font-size: 1.3rem;

    &-top {
        padding-top: 2rem;
        padding-bottom: 5rem;
        text-align: center;

        &.container {
            margin-bottom: 3rem;
        }

        img {
            margin: 0 auto;
            width: 60px;
            height: 60px;
            margin-bottom: 3rem;
        }

        .title {
            font-size: 7.5rem;
            line-height: 5.5rem;
            //margin-top: -2rem;
        }

        h2 {
            font-family: $font-application-black;
            font-size: 2.9rem;
            line-height: 2.9rem;
            margin-top: 1rem;
        }
    }

    .container-box {
        color: $white;
        width: 100%;

        &--title {
            margin-bottom: 4rem;

            p {
                font-family: $caveat;
                font-size: 6.1rem;
                //letter-spacing: -.25rem;

                span {
                    color: $green;
                }
            }
        }

        &--content {
            p,
            a {
                font-size: 1.5rem;
                font-weight: 800;
                letter-spacing: 0.1rem;
                line-height: 1.8rem;

            }

            p {
                margin-bottom: 1.5rem;
            }
        }
    }

    &-special-prize {
        display: inline-block;
        margin: 4rem 0 0;
        width: 100%;

        p.separator {
            background: url('/images/dotted__separator.png') repeat-x scroll left top transparent;
            height: 2px;
            padding-bottom: 2rem;
            width: 100%;
        }

        img {
            float :right;
        }

        .subtitle {
            span {
                text-decoration: underline;
            }
        }

        ul {
            margin-bottom: 0 !important;
            margin-top: 2rem;

            li {
                margin-bottom: 1rem;
            }
        }
    }

    &-daily-question {
        border-top:1px solid $grey-tint-3;
        display: inline-block;
        margin: 4rem 0 4rem;
        padding-top: 4rem;
        width: 100%;

        img {
            float: right;
            margin-top: 1.5rem;
            max-width: 120px;
        }

        .subtitle {
            margin-bottom: 1.5rem;
        }
    }

    .quiz-form-container {
        margin-bottom: 3rem;

        ul {
            margin-bottom: 5rem;
        }

        p {
            font-size: 1.3rem;
            line-height: 2.3rem;
            margin-bottom: 1.5rem;
        }
    }

    .thankyou-facebook {
        margin-bottom: 1rem;

        li {
            line-height: 2.3rem;
        }
    }
}

