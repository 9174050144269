.account-container section.raport-user {
    padding-right: 0;
}

.raport-custom-sondaj,
.raport-user {
    padding-right: 0;

    .subtitle-big {
        margin-bottom: 5rem;
    }

    .error-message {
        color: $dark-blue;
    }

    .charts-container {
        padding: 0;
        margin: 0;

        .chart {
            margin: 0 auto 0 auto;
            padding: 0;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            -webkit-flex-direction: column;

            canvas {
                background-color: rgba(255,255,255,1);
                border-radius: 1.6rem;
                max-width: 100%;
                box-sizing: border-box;
                order: 2;
                padding: 1.5rem;
            }

            .chart__info {
                font-family: $font-application-black;
                font-size: 2.3rem;
                order: 1;
            }
        }
    }

    #my_chart_judete {
        border-bottom: 0.1rem solid $grey-tint-3;
        text-align: center;
        margin-bottom: 5rem;
        padding-bottom: 8rem;

        .charts-container {
            .chart {
                width: 85%;
            }
        }
    }

    .list-charts,
    .questions-charts {
        .new-bar-chart {
            margin-bottom: 5rem;

            > div {
                box-sizing: border-box;
                height: 100%;
            }
        }

        .charts-container {
            height: 100%;

            .chart {
                height: 100%;
                width: 90%;

                canvas {
                    //align-self: flex-end;
                }

                .chart__info {
                    flex-grow: 1 !important;
                    -moz-flex-grow: 1;
                    -webkit-flex-grow: 1;
                }
            }
        }
    }

    .pagesQuiz {
        clear: both;
        margin-bottom: 5rem;

        .button {
            margin: 0;
        }
    }

    .question-chart + .pagesQuiz {
        margin-top: 5rem;
    }

    &.raport-user-admin {
        &.raport-user-admin {
            .list-charts {
                border-bottom: 1px solid $grey-tint-3;

                > div {
                    text-align: center;

                    .subtitle {
                        margin-bottom: 3rem;
                    }

                    img {
                        background-color: $white;
                        box-sizing: border-box;
                        border-radius: 1.6rem;
                        max-width: 92%;
                        padding: 1.5rem;
                        margin-bottom: 8rem;
                    }

                    &#my_chart_judete {
                        border: 0;
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            .link-graph-style {
                float: right;

                a {
                    color: $green;
                    font-weight: 700;
                }
            }

            .conditie {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: row;
                -webkit-flex-direction: row;
                padding: 1.5rem 0 2.5rem 0;

                .button {
                    text-align: center;
                    margin:0 0 0 1.5rem;
                    width: 24rem;
                }
            }

            .question-chart {
                border-bottom: 1px solid $grey-tint-3;
                padding: 5rem 0;

                .subtitle {
                    margin-bottom: 3rem;
                }

                img {
                    background-color: $white;
                    box-sizing: border-box;
                    border-radius: 1.6rem;
                    max-width: 92%;
                    padding: 1.5rem;
                }
            }
        }
    }
}

.profil-users,
.raport-intrebare,
.raport-sondaj,
.raport-sanse,
.users-sondaj,
.raport-custom-sondaj {
    .container {
        text-align: center;
    }

    h2 {
        color: $dark-blue;
        font: {
            family: $font-application-black;
            size: 2.3rem;
        }
        letter-spacing: -1px;
        padding: 4px 0 14px;
    }

    h4 {
        color: $blue-tint-1;
        font: {
            family: $font-application-black;
            size: 1.6rem;
        }
        padding-bottom: 1.5rem;
    }

    .btn {
        float: right;
    }

    .chart_intrebarea_zilei {
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    div.btn .Button {
        background: none repeat scroll 0 0 #34A5A2;
        color: #FFFFFF;
        cursor: pointer;
        padding: 5px;
    }
}

.download-uzers {
    margin-bottom: 5rem;

    .button {
        float: right;
    }

    div {
        width: 100%;
        @extend %display-inline;

        .button {
            margin: 0 0 0 1.5rem;
        }
    }
}

.export-link {
    display: inline-block;
    margin: 3rem 0 0 0;
    width: 100%;
}

.raport-sanse {
    table {
        &.Account {
            margin-top: 3rem;
        }
    }
}

.raport-custom-sondaj {
    .fieldset-line {
        display: inline-block;
        margin-bottom: 3rem;
        width: 100%;

        label {
            margin-bottom: 1rem;
            text-align: left;
        }

        .line {
            margin-bottom: 0;
            @extend %display-inline;
            @extend %align-items;

            .button {
                margin: 0 0 0 0.5rem;
            }
        }


        select {
            margin-right: 0.5rem;
            max-width: 60rem;
        }

        .input {

            max-width: 60rem;
            width: 100%;

            select {
                border: 0.3rem solid $grey-tint-3;
                width: 100%;
            }
        }
    }

    .buttons {
        .btn {
            float: left;
        }

        .button {
            float: left;
            margin: 0 2rem 2rem 0;
        }
    }

    &.raport-user-admin {
        .list-charts {
            margin-bottom: 5rem;

            > div {
                img {
                    margin-bottom: 3rem !important;
                }

                table {
                    margin-bottom: 5rem;
                }
            }
        }
    }

    .questions-charts {
        .new-bar-chart {
            > div {
                height: auto;
            }

            .subtitle {
                margin-bottom: 2rem;
            }

            img {
                background-color: #fff;
                box-sizing: border-box;
                border-radius: 1.6rem;
                max-width: 92%;
                padding: 1.5rem;
                margin-bottom: 3rem;
            }
        }
    }

}

table {
    &.Account,
    &.raport-table {
        background-color: $white;
        font-size: 1.3rem;
        margin: 0 auto 4rem auto;
        max-width: 600px;
        width: 100%;

        th {
            box-sizing: border-box;
            padding: 10px 5px 11px 5px;
            border: 1px solid #DDD;
            background: $grey-tint-2;
            color:$dark-blue;
            font-size: 1.1rem;
            font-weight: normal;
            text-align:center;
        }

        tr:hover {
            background-color: #FFFFCC;
        }

        td {
            box-sizing: border-box;
            padding: 10px 5px 11px 5px;
            border: 1px solid #DDD;
            font-size: 1.1rem;
            vertical-align: middle;
            word-break: break-word;
        }

        .First {
            text-align:right;
        }

        td.Punct span {
            font:bold 14px Arial, Helvetica, sans-serif;
            color:#333;
        }
    }
}

.position-left {
    max-width: 60rem;
    float: left;
    width: 100%;

    h4 {
        text-align: left;
    }
}

.position-right {
    max-width: 60rem;
    float: right;
    width: 100%;

    h4 {
        text-align: left;
    }
}

@media only screen and (min-width: $screen-sm) {
    table {
        &.Account {
            th {
                padding: 10px 12px 11px 12px;
                font-size: 1.3rem;
            }

            td {
                padding: 10px 12px 11px 12px;
                font-size: 1.3rem;
            }
        }
    }

    .raport-custom-sondaj,
    .raport-user {
        #my_chart_judete {
            .charts-container {
                .chart {
                    width: 55%;
                }
            }
        }

        .list-charts,
        .questions-charts {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: row;
            -webkit-flex-direction: row;
            flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            flex: 1 1 auto;
            -webkit-flex: 1 1 auto;
            max-width: 100%;
            width: 100%;

            .new-bar-chart {
                width: 50%;
            }
        }

        &.raport-user-admin {
            .list-charts {
                > div {
                    text-align: center;
                    width: 50%;
                }
            }

            .question-chart-details {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: row;
                -webkit-flex-direction: row;

                > div {
                    width: 50%;
                }
            }
        }
    }
}