/*----------------------------------
    #COLORS
----------------------------------*/

$white: #FFFFFF;
$black: #000000;
$orange: #FF8C33;
$red: #FF0000;


$green: #26C699;
$green-tint-1: #0ba177;

$dark-blue: #262c39;
$blue-tint-1: #333E50;
$blue-tint-2: #3B5998;

$grey: #EDEDED;
$grey-tint-1: #F2F2F2;
$grey-tint-2: #E8E8E9;
$grey-tint-3: #DFE0E1;
$grey-tint-7: #D4D5D9;
$grey-tint-8: #C9CACE;
$grey-tint-5: #A0A3A8; // 40%
$grey-tint-4: #888888;
$grey-tint-6: #8C8F95; // 50%

/*----------------------------------
    #Screen Resolutions
----------------------------------*/

$screen-xxs: 375px;
$screen-xs: 425px;
$screen-s: 640px;
$screen-sm: 768px;
$screen-m: 1024px;
$screen-l: 1300px;
$screen-xl: 1440px;