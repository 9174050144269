.ivox-info__description {
    font-weight: 700;
    margin-top: 2rem;
    padding: 0 2rem;
}

.ivox-info__subtitle {
    font-size: 4.1rem;
    box-sizing: border-box;
    margin: 0;
    padding: 2rem;
}

.email-invitations {
    background-color: $white;
    box-shadow: 0 .5rem .75rem rgba(0, 0, 0, .2);
    border-radius: 1.6rem;
}

.ivox-info__description--grey {
    margin: 1rem 0;
    padding: 1.5rem 2rem;

    &:last-child {
        padding-bottom: 3rem;
    }
}